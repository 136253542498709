<template>
  <v-app>
    <v-container class="justify-center">
      <v-row class="justify-center">
        <base-material-card
          color="primary"
          class="pa-3 px-5 py-3"
          style="margin-top: 20%;max-width:100%;width:700px"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light text-center">
              Login
            </div>
          </template>
          <v-card-text>
            <v-alert
              v-if="isError"
              type="error"
              close-text="Close Alert"
              dark
              dismissible
            >
              {{ error_message }}
            </v-alert>
            <v-card-text>
              <v-form
                v-model="isValid"
                @submit.prevent="login"
              >
                <v-text-field
                  v-model="username"
                  :rules="emailRules"
                  label="Email or NoHandphone"
                  prepend-icon="mdi-account-circle"
                />
                <v-text-field
                  v-model="password"
                  label="Password"
                  :rules="passwordRules"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="handleclick"
                />

                <v-card-actions>
                  <v-btn
                    depressed
                    color="success"
                    type="submit"
                    :disabled="!isValid"
                    @click.prevent="login"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card-text>
        </base-material-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'Login',
    data () {
      return {
        showPassword: false,
        username: '',
        password: '',

        emailRules: [value => value !== '' || 'Email or Username is required '],
        passwordRules: [
          value => value !== '' || 'password is required',
          value => value.length <= 8 || 'password maximall 8 carakter',
        ],
        isValid: false,
        isError: false,
        error_message: '',
      }
    },
    methods: {
      handleclick () {
        this.showPassword = !this.showPassword
      },
      // handlesubmit () {
      //   console.log(this.user)
      //   this.user = {
      //     email: '',
      //     password: '',
      //   }
      // },
      login () {
        this.$store
          .dispatch('retrieveToken', {
            username: this.username,
            password: this.password,
          })
          .then(response => {
            if (response.data.meta.status === true) {
              const redirecturl = this.$route.query.redirect || '/'
              this.$router.push(redirecturl)
            } else if (response.data.meta.status !== true) {
              this.isError = true
              this.error_message = response.data.meta.message
            }
          })
          .catch(e => {})
      // if(this.username && this.password){
      //   this.$router.push({name : 'Dashboard'})
      // }else{
      //   console.log('password Anda Salah');
      // }
      },
      getError () {
        return 'hahaha'
      },
    },
  }
</script>

<style></style>
